import React from 'react'
import { Container } from './style'
import { doc, deleteDoc } from 'firebase/firestore';
import { db } from '../../config/firebase';

export const ClientCard = ({ item }) => {
    // Suponha que você tem o ID do documento que deseja excluir
    const documentId = item.id;
    // Crie uma referência ao documento que deseja excluir
    const docRef = doc(db, 'services', documentId);

    return (
        <Container>
            <div className="containerTitleDelete">
                <span className='area'> {item.title} - {item.area}</span>
                <div className="btnDelete">
                    <button
                        onClick={() => {
                            // Exclua o documento usando o método delete()
                            deleteDoc(docRef)
                                .then(() => {
                                    console.log('Documento excluído com sucesso!');
                                })
                                .catch((error) => {
                                    console.error('Erro ao excluir o documento:', error);
                                });
                        }}
                    >
                        X
                    </button>
                </div>{/*btnDelete */}

            </div>{/*containerTitleDelete*/}


            <div className="containerUserData">
                <p className='description'>{item.description}</p>
                <p>{item.createdAt && item.createdAt.toDate().toLocaleString()}</p>
            </div>{/*containerUserData */}
        </Container>
    )
}
