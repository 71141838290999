import styled from "styled-components"

export const Container = styled.div`
    padding: 40px;
    display: flex;
    justify-content: center;
    .containerServicesList{

        display: flex;
        flex-direction: column;
        gap: 20px;
    }
    .containerEmptyList{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        h3{
            font-size: 20px;
            font-weight: 900;
            text-transform: uppercase;
            padding: 40px 40px 20px 40px;
        }

        button{
            border: 0;
            padding: 10px;
            font-weight: 900;
            text-transform: uppercase;
            background-color: #000;
            color: #fff;
            cursor: pointer;

        }
    }
`
