import styled from "styled-components"

export const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 20px;
  font-weight: bold;
  text-align: center;
  .containerTitle{
    display: flex;
    justify-content:center;  
    h2{
      font-weight: 900;
      max-width: 400px;
      text-transform: uppercase;
      text-align: center;
    }
  }
    .containerAllItems{
      width: 100%;
      display: flex;
      justify-content: center;

      .markedBtn{
        width: calc(33.33333% - 10px);
        height: 300px;
        border: solid 1px #ccc;
        background-color: hsl(352, 69%, 51%);
        color: #000;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 900;
        text-transform: uppercase;
        font-size: 20px;
        padding: 15px;
        transition: ease-in-out .2s;
        cursor: pointer;
        margin-top: 10px;
      }

      .containerSingleArea{
        margin-top: 10px;
        width: calc(33.33333% - 10px);
        height: 300px;
        border: solid 1px #ccc;
        background-color: #fff;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 900;
        text-transform: uppercase;
        font-size: 20px;
        padding: 15px;
        transition: ease-in-out .2s;
        cursor: pointer;

        @media (max-width: 768px) {
        }
      }

    }

  .btnSend{
      width: 200px;
      height: 70px;
      border: 0;
      font-weight: 900;
      font-weight: 20px;
      text-transform: uppercase;
      background-color: hsl(352, 69%, 51%);
      cursor: pointer;
      border-radius: 5px;
      transition: ease-in-out .2s;
      color: #fff;
      display: inline-block;
  }
  .btnSend:hover{
      background-color: #000;
  }

`
