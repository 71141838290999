import React, { useContext, useEffect, useState } from 'react'
import { Container } from './style'
import axios from "axios"
import { Link } from 'react-router-dom'
import { UserInfoContext } from '../../../context/userInfoContext'

const BoletoScreen = ({ price, quantity }) => {
  const [copiedBoleto, setCopiedBoleto] = useState(false)
  const [boletoCode, setBoletoCode] = useState()
  const { userInfo, setUserInfo } = useContext(UserInfoContext)
  const [inputs, setInputs] = useState({
    name: userInfo[0].userName,
    email: userInfo[0].email,
  })
  const [cpf, setCpf] = useState('');

  const handleCpfChange = (event) => {
    // Atualiza o state 'cpf' com o valor digitado no input
    setCpf(event.target.value);
  };

  const [generateBoleto, setGenerateBoleto] = useState(false)


  console.log()

  function addThreeDaysToCurrentDate() {
    // Obtém a data atual
    const currentDate = new Date();

    // Acrescenta 3 dias à data atual
    currentDate.setDate(currentDate.getDate() + 3);

    // Formata a data no formato "YYYY-MM-DD"
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0');
    const day = String(currentDate.getDate()).padStart(2, '0');

    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  }



  const getBoleto = async () => {
    console.log('valores: ', {
      name: inputs.name,
      cpf: cpf,
      quantity: quantity,
      email: inputs.email,
      price: price * 100,
      expirationDate: addThreeDaysToCurrentDate()
    })
    const res = await axios.post("https://server-piclobby.vercel.app/api/BoletoController", {

      name: inputs.name,
      cpf: cpf,
      quantity: quantity,
      email: inputs.email,
      price: price * 100,
      expirationDate: addThreeDaysToCurrentDate()

    })
      .then((res) => {
        setBoletoCode(res.data);
        setCopiedBoleto(true);
      })
      .catch((error) => {
        console.error('Erro ao obter o código do Boleto', error);
      });
  };


  const handleClick = () => {
    // Tenta copiar o código para a área de transferência
    try {
      navigator.clipboard.writeText(boletoCode[1]);
      setCopiedBoleto(true);
    } catch (err) {
      console.error('Falha ao copiar o código do Boleto', err);
    }
  };

  return (
    <Container>
      <h2>A sua compra está quase finalizada!</h2>
      <h3>Forma de pagamento: Boleto</h3>
      <div className="containerBoletoCode">
        <h3>Valor do pacote: {price}</h3>
        <span>Clique no botão abaixo e aguarde que o código do seu boleto será copiado ou clique em gerar boleto para ter o seu boleto em pdf</span>
        <form action="">
          <input
            type="text"
            className='CPF'
            placeholder='Insira o seu CPF'
            value={cpf} // Define o valor do input como o valor do state 'cpf'
            onChange={handleCpfChange} // Chama a função handleCpfChange quando houver mudanças no input
            required
          />


          <button
            className='generateBoleto'
            onClick={
              cpf !== "" ?
                (e) => {
                  e.preventDefault()
                  getBoleto()
                  setGenerateBoleto(true)
                }
                :
                () => {

                }
            }
          >
            Gerar boleto
          </button>
        </form>

        {generateBoleto ?
          copiedBoleto ?
            <>
              <h4>Seu boleto foi gerado abaixo:</h4>
              <div className="containerBoleto">
                <input type="text" value={boletoCode[1]} disabled />
                <button className='boletoCopy' onClick={handleClick}>
                  Copiar código do Boleto
                </button>

                <Link className='pdfBoleto' to={boletoCode[0]}>Baixar em PDF</Link>

              </div>

            </>
            :
            <div className="containerBoleto">
              <button className='boletoCopy'>
                Aguarde...
              </button>
            </div>
          :
          <></>
        }

        <p>Assim que o pagamento for compensado você receberá as suas moedas!</p>
        <span>Pagamentos com boleto demoram até 3 dias úteis para compensarem!</span>
      </div>{/*containerBoletoCode*/}
    </Container>
  )
}

export default BoletoScreen
