import styled from "styled-components"

export const Container = styled.div`
    display: flex;
    padding: 40px;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    h2{
        font-weight: 900;
    }
`