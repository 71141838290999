import React, { useContext, useState } from 'react'
import { Container } from './style'
import { Link, useNavigate } from 'react-router-dom'
import { signInWithEmailAndPassword } from 'firebase/auth'
import { auth, db } from '../../config/firebase'
import { UserInfoContext } from '../../context/userInfoContext'
import { collection, onSnapshot, query, where } from 'firebase/firestore'
import Logo from "../../img/logores.png"


export const Login = () => {

  const { userInfo, setUserInfo } = useContext(UserInfoContext)

  const navigate = useNavigate()
  const [input, setInput] = useState({
    email: "",
    password: ""
  })

  const [errorMsg, setErrorMsg] = useState()


  const handleChange = (e) => {
    setInput(prev => ({ ...prev, [e.target.name]: e.target.value }))
  }

  return (
    <Container>
      <div className="containerLogo">

        <h1>FreelaVerse</h1>
        <img src={Logo} alt="" />
      </div>{/*containerLogo */}
      <div className="form">
        <h2>Faça login abaixo</h2>
        <form action="">
          <input type="text" placeholder='email' name='email' onChange={handleChange} />
          <input type="password" placeholder='senha' name='password' onChange={handleChange} />
          <button

            onClick={(e) => {
              e.preventDefault()
              signInWithEmailAndPassword(auth, input.email, input.password)
                .then(() => {

                  //Atribuindo informações do Usuário
                  navigate("/")
                })
                .catch((err) => {
                  if (err.message === "Firebase: Error (auth/wrong-password).") {
                    setErrorMsg("Email ou senha incorreto!")
                  }
                  if (err.message === "Firebase: Error (auth/invalid-email).") {
                    setErrorMsg("Endereço de email inválido!")
                  }
                  if (err.message === "Firebase: Error (auth/user-not-found).") {
                    setErrorMsg("Usuário não encontrado!")
                  }
                });
            }}

          >Fazer login</button>
        </form>
        {errorMsg && <div className='errMsg'><span>{errorMsg}</span></div>}
      </div>{/*form*/}
      <p>não é cadastrado? <Link to="/Register">Cadastre-se</Link></p>
    </Container>
  )
}
