import React, { createContext, useState } from 'react';

export const PaymentViewContext = createContext();

export const PaymentViewProvider = ({ children }) => {
  const [paymentView, setPaymentView] = useState();

  return (
    <PaymentViewContext.Provider value={{ paymentView, setPaymentView }}>
      {children}
    </PaymentViewContext.Provider>
  );
};