import React, { useContext, useEffect, useState } from 'react'
import { Container } from './style'
import { auth, db } from '../../config/firebase'
import { collection, onSnapshot, query, where } from 'firebase/firestore'
import { useNavigate } from 'react-router-dom'
import { ServiceViewContext } from '../../context/serviceViewContext'
import { Cards } from '../../components/home/cards/Cards'
import { CardUnloked } from '../../components/unlokedServices/cardUnloked/CardUnloked'

export const UnlokedServices = () => {
  const { service, setService } = useContext(ServiceViewContext);

  // Services
  const colItemsRef = collection(db, "services");
  const findServices = query(colItemsRef);
  const [services, setServices] = useState([]);

  // UserId
  const userRef = collection(db, "users");
  const { currentUser } = auth;
  const findUserId = query(userRef, where("email", "==", currentUser.email));
  const [userId, setUserId] = useState();

  // RelationUserServices
  const relationRef = collection(db, "relationServicesUsers");

  useEffect(() => {
    // Fetch services
    onSnapshot(findServices, (snapshot) => {
      const items = [];
      snapshot.docs.forEach((doc) => {
        items.push({ ...doc.data(), id: doc.id });
      });
      setServices(items);
    });

    // Fetch user ID
    onSnapshot(findUserId, (snapshot) => {
      let users = [];
      snapshot.docs.forEach((doc) => {
        users.push({ ...doc.data(), id: doc.id });
      });
      setUserId(users[0].id);
    });
  }, [userId]);

  useEffect(() => {
    // Fetch and filter user services
    if (userId !== undefined) {
      const findRelUserService = query(relationRef, where("userId", "==", userId));
      onSnapshot(findRelUserService, (snapshot) => {
        let relUsersServices = [];
        snapshot.docs.forEach((doc) => {
          relUsersServices.push({ ...doc.data(), id: doc.id });
        });
        // Filter services based on user ID
        const userServices = services.filter((item) =>
          relUsersServices.some((relItem) => item.id === relItem.serviceId)
        );
        setServices(userServices);
      });
    }
  }, [userId]);

  
  const navigate = useNavigate()

  return (
    <Container>
      <div className="containerServicesList">
        {
          services.length > 0 ?
          
            services.map((item)=>{
              
              return(
                <div
                  className="containerCard"
                  key={item.id}
                  onClick={()=>{
                    setService(item)
                    navigate("/service")
                  }}
                >
                  <CardUnloked item={item}/>
                </div>
              )
            })
          :
          (
            <div className="containerEmptyList">
              <h3>Você ainda não liberou nenhum pedido!</h3>
              <button onClick={()=>navigate("/")}>Voltar para o início</button>
            </div>/*containerEmptyList*/
          )
        }
      </div>{/*containerServicesList */}
    </Container>
  )
}
