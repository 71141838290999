import React, { useEffect, useState } from 'react'
import { Container } from './style'
import { Footer } from '../../components/footer/Footer'
import { collection, onSnapshot, query, where } from 'firebase/firestore'
import { auth, db } from '../../config/firebase'

export const Profile = () => {

  //UserInfo

  const [userInfo, setUserInfo] = useState()
  const colRef = collection(db, "users")
  const { currentUser } = auth;
  const findUsername = query(colRef, where("email", "==", currentUser.email))

  useEffect(() => {
    onSnapshot(findUsername, (snapshot) => {
      let users = []
      snapshot.docs.forEach((doc) => {
        users.push({ ...doc.data(), id: doc.id })
        setUserInfo(users)
      })
    })
  }, [])


  //AllPosts
  const [allPosts, setAllPosts] = useState()
  const postRef = collection(db, "posts")
  const findPosts = query(postRef)

  useEffect(() => {
    onSnapshot(findPosts, (snapshot) => {
      let posts = []
      snapshot.docs.forEach((doc) => {
        posts.push({ ...doc.data(), id: doc.id })
        setAllPosts(posts)
      })
    })
  }, [])


  //Pegando posts com o id do usuário

  const [relUserPost, setRelUserPost] = useState({})
  const relationRef = collection(db, "relationUserPosts")
  useEffect(() => {
    if (allPosts !== undefined) {

      const findRelUserPost = query(relationRef, where("userId", "==", userInfo[0].id))

      onSnapshot(findRelUserPost, (snapshot) => {
        let relUsersPosts = []
        snapshot.docs.forEach((doc) => {
          relUsersPosts.push({ ...doc.data(), id: doc.id })
        })
        setRelUserPost(relUsersPosts)
      })
    }
  }, [allPosts])


  const newService = null;


  
  useEffect(()=>{
    if (relUserPost[0] !== undefined) {
      allPosts.forEach((item) => {
        relUserPost.forEach((item1) => {

          if (item.id === item1.postId){
            
            setAllPosts((current) =>
              current.filter((setServices) => setServices.id !== item1.postId)
            );
            
          }
        })
      })
    }
  },[relUserPost])


  return (
    
    <Container>
      {userInfo ?  // Verifica se userInfo está definido (não é nulo ou indefinido)
        <div className="containerUserView">

          <div className="containerUserInfo">
            <div className="userImg">
              <img src={userInfo[0]?.img} alt="" />
            </div>{/*userImg*/}
            <div className="containerTexts">
              <h2>{userInfo[0]?.userName}</h2>
              <div className="social">
                <img src="https://cdn.pixabay.com/photo/2016/08/09/17/52/instagram-1581266_640.jpg" alt="" />
                <img src="https://cdn.pixabay.com/photo/2017/08/22/11/56/linked-in-2668700_1280.png" alt="" />
                <img src="https://cdn.pixabay.com/photo/2016/07/03/18/36/youtube-1495277_1280.png" alt="" />
                <img src="https://cdn.pixabay.com/photo/2015/05/17/10/51/facebook-770688_1280.png" alt="" />
                <img src="https://cdn.pixabay.com/photo/2022/01/30/13/33/github-6980894_1280.png" alt="" />
              </div>{/*social*/}
              {/* Resto do código do seu componente */}
            </div>{/*containerTexts*/}
          </div>{/*containerUserInfo */}

          {allPosts &&
            allPosts.map((item)=>{
              return(
                <div className="containerPosts" key={item.id}>
                  <div className="containerSinglePost">
                    <img src={item.img} alt="" />
                  </div>{/*containerSinglePost*/}
                </div>/*containerAllPosts*/
              )
            })
          }

        </div>/*containerUserView */
        :
        <p>Carregando...</p>
      }

    </Container>
    
  )
}
