import styled from "styled-components"

export const Container = styled.div`
width: 100%;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
gap: 20px;
padding-top: 40px;
h2{
    text-transform: uppercase;
    font-size: 23px;
    font-weight: 900;
}
p{
    color: #777;
}
.containerCardCode{
    border: solid 1px #ccc;
    padding: 50px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    h3{
        font-weight: 900;
        font-size: 20px;
    }
    span{
        max-width: 500px;
        font-weight: bold;
        font-size: 20px;
    }
    input{
        border: #ccc solid 1px;
        padding: 10px;
        font-weight: bold;
        font-size: 20px;
    }

    select{
        border: #ccc solid 1px;
        padding: 10px;
        font-weight: bold;
        font-size: 20px;
        text-align: center;
        color: #aaa;
    }

    form{
        display: flex;
        flex-direction: column;
        gap: 10px;
        .containerThreeInputs{
            display: flex;
            gap: 10px;
            width: 100%;
            input{
            width: 100%;
        }
        }
        button.btnBuy{
            background-color: rgb(38, 160, 74);
            font-weight: 900;
            font-size: 30px;
            text-transform: uppercase;
            padding: 10px;
            border-radius: 5px;
            border: 0;
            cursor: pointer;
            transition: ease-in-out .2s;
            p{
                color: #fff;
            }
        }
        button.btnBuy:hover{
            background-color: rgb(34, 197, 83);
        }
    }
    button.comeBackHome{
        background-color: #000;
            font-weight: 900;
            font-size: 30px;
            text-transform: uppercase;
            padding: 10px;
            border-radius: 5px;
            border: 0;
            cursor: pointer;
            transition: ease-in-out .2s;
            color: #fff;
    }
}

`