import React, {useContext, useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import CheckoutForm from "./CheckoutForm";
import { Container } from "./styles";
import { UserInfoContext } from '../../../context/userInfoContext'

// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// This is your test publishable API key.
const stripePromise = loadStripe("pk_live_51KnbllDgt2Zurb9q76gMfvUgp5vJdTpftSclQq6FTfU6ETO4rAXhozl0KN5tv9ltZbYKDvQJQMle02ZnoFF9vxBe00Lo3pxRlN");

export default function CardView({price, quantity, email}) {
  const { userInfo, setUserInfo } = useContext(UserInfoContext)
  const [clientSecret, setClientSecret] = useState("");

  useEffect(() => {
    // Create PaymentIntent as soon as the page loads
    console.log(userInfo[0]?.email)
    fetch("https://payment-api-one.vercel.app/api/StripeCardController", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ items: [{ id: "xl-freelaverse" }],price: price*100, accountEmail: userInfo[0]?.email }),
    })
      .then((res) => res.json())
      .then((data) => setClientSecret(data.clientSecret));
  }, []);

  const appearance = {
    theme: 'stripe',
  };
  const options = {
    clientSecret,
    appearance,
  };

  return (
    <Container>

      <div className="App">
        {clientSecret && (
          <Elements options={options} stripe={stripePromise}>
            <CheckoutForm price={price} />
          </Elements>
        )}
      </div>
    </Container>
  );
}
