import React, { useContext, useEffect, useState } from 'react'
import { Container } from './style'
import { collection, onSnapshot, query, where } from 'firebase/firestore'
import { auth, db } from '../../config/firebase'
import { List } from '../../components/list/List'
import { ClientForm } from '../../components/clientForm/ClientForm'
import { ServiceViewProvider } from '../../context/serviceViewContext'
import { Aside } from '../../components/aside/Aside'
import { UserInfoContext } from '../../context/userInfoContext'

export const Home = () => {
  
  const {userInfo, setUserInfo} = useContext(UserInfoContext)
  const colRef = collection(db, "users")
  const { currentUser } = auth;
  const findUsername = query(colRef, where("email", "==", currentUser.email))

  const [userType, setUserType] = useState()

  useEffect(() => {
    const unsubscribe = onSnapshot(findUsername, (snapshot) => {
      let users = []
      snapshot.docs.forEach((doc) => {
        users.push({ ...doc.data(), id: doc.id })
      })
  
      if (users.length > 0) {
        setUserType(users[0].userType)
        setUserInfo(users)
      } else {
        // Usuário não encontrado, faça algo apropriado aqui (por exemplo, redirecionar para uma página de erro)
      }
    });
  
    return () => unsubscribe();
  }, []);

  const UserTypeView = ()=>{
    if(userType === "professional"){
      return(
        <div className="containerClientList">
          <div className="containerAside">
            <Aside/>
          </div>{/*containerAside */}
          <div className="containerList">
            <List/>
          </div>{/*containerList */}
        </div>/*containerClientList*/
      )
    }else if(userType === "client"){
      return(
        <div className="containerClientList">
          <ClientForm/>
        </div>/*containerClientList*/
      )
    }
  }

  return (
      
      <Container>
        <UserTypeView/>
      </Container>
  )
}
