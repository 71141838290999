import React, { useContext } from 'react'
import { Container } from './style'
import { PaymentViewContext } from '../../../context/paymentViewContext'
import PixScreen from '../PIXScreen/PixScreen'
import BoletoScreen from '../BoletoScreen/BoletoScreen'
import CardScreen from '../CardScreen/CardScreen'
import CardView from '../CardView/CardView'
import { UserInfoContext } from '../../../context/userInfoContext'

export const PaymentOptions = ({ price, quantity }) => {
    
    const { userInfo, setUserInfo } = useContext(UserInfoContext)
    const { paymentView, setPaymentView } = useContext(PaymentViewContext)
    return (
        <Container>


            <h2>Escolha a forma de pagamento</h2>
            <div className="containerPackages">
                <div
                    className="containerSinglePackage"
                    onClick={() => {
                        setPaymentView(<PixScreen price={price} quantity={quantity} />)
                    }}
                >

                    <img src="https://devtools.com.br/img/pix/logo-pix-png-icone-520x520.png" alt="" />
                    <div className="containerText">
                        <h3>PIX</h3>

                    </div>{/*containerText */}
                    <button>Comprar</button>
                </div>{/*containerSinglePackage */}

                <div className="containerSinglePackage">
                    <img src="https://cdn-icons-png.flaticon.com/512/2695/2695969.png" alt="" />
                    <div className="containerText">
                        <h3>Cartão</h3>
                    </div>{/*containerText */}
                    <button
                        onClick={() => {
                            setPaymentView(<CardView price={price} quantity={quantity} email={userInfo[0]?.email} />)
                        }}
                    >Comprar</button>
                </div>{/*containerSinglePackage */}

                <div className="containerSinglePackage">
                    <img src="https://cdn-icons-png.flaticon.com/512/5984/5984665.png" alt="" />
                    <div className="containerText">
                        <h3>Boleto</h3>
                    </div>{/*containerText */}
                    <button
                        onClick={() => {
                            setPaymentView(<BoletoScreen price={price} quantity={quantity} />)
                        }}
                    >Comprar</button>
                </div>{/*containerSinglePackage */}

            </div>{/*containerPackages*/}

        </Container>
    )
}
