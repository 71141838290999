import styled from "styled-components"

export const Container = styled.div`
    position: relative;
    top: 100px;
    z-index: -1;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    .containerServicesList{
        width: 100%;
        max-width: 1200px;
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
    .containerEmptyList{
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        h3{
            font-size: 20px;
            font-weight: 900;
            text-transform: uppercase;
            padding: 40px;
        }
    }
`
