import React, { useContext, useEffect, useState } from 'react'
import { Container } from './style'

export const CardUnloked = ({item}) => {

  return (
    <Container>
      <span className='area'>
      {item.title} - {item.area}</span>
      <p className='description'>{item.description}</p>
      <div className="containerUserData">
        <p className='username'>Usuário: {item.userName}</p>
        <p>{item.createdAt && item.createdAt.toDate().toLocaleString()}</p>
      </div>{/*containerUserData */}
      <div className="containerBtn">
        <button>
          Ver Mais
        </button>
      </div>{/*containerBtn*/}
    </Container>
  )
}
