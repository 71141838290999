import React, { useContext, useEffect, useState } from 'react'
import { ServiceViewContext } from '../../context/serviceViewContext'
import { Container } from './styled'
import coinImg from "../../img/coin.png"
import { addDoc, collection, doc, onSnapshot, query, serverTimestamp, updateDoc, where } from 'firebase/firestore'
import { auth, db } from '../../config/firebase'
import { Link } from 'react-router-dom'

export const InfoService = () => {

  const { service, setService } = useContext(ServiceViewContext)
  const [errorMsg, setErrorMsg] = useState();
  const [unlocked, setUnlocked] = useState(false);

  //UserName
  const userRef = collection(db, "users")
  const { currentUser } = auth;
  const findUsername = query(userRef, where("email", "==", currentUser.email))

  const [user, setUser] = useState({})
  const [userName, setUserName] = useState()

  useEffect(() => {
    onSnapshot(findUsername, (snapshot) => {
      let users = []
      snapshot.docs.forEach((doc) => {
        users.push({ ...doc.data(), id: doc.id })
        setUser(users)
      })
    })
  }, []);

  //RelationUserService

  const [relUserService, setrelUserService] = useState({})
  const relationRef = collection(db, "relationServicesUsers")
  const [phoneNumber, setPhoneNumber] = useState()
  useEffect(() => {
    if (user[0] !== undefined) {
      setPhoneNumber(service.phoneNumber)

      const findRelUserService = query(relationRef, where("userId", "==", user[0].id))

      onSnapshot(findRelUserService, (snapshot) => {
        let relUsersServices = []
        snapshot.docs.forEach((doc) => {
          relUsersServices.push({ ...doc.data(), id: doc.id })
        })
        setrelUserService(relUsersServices)
      })
      setUserName(user[0].userName)
    }
  }, [user])



  //Juntando tudo

  useEffect(() => {
    if (relUserService[0] !== undefined) {
      relUserService.forEach(element => {
        if (element.serviceId === service.id) {
          setUnlocked(true)
        }
      });
    }
  }, [relUserService])

  const message = `Olá ${service.userName}!

    Vi seu pedido no FreelaVerse, sou um profissional cadastrado na plataforma. Me chamo ${userName} e você pode conferir o meu anúncio no link a seguir: https://www.freelaverse.com.br
    
    [Responda a mensagem para acessar o link]

    Tenho muito interesse em te atender, você pode me passar mais alguns detalhes do serviço para eu fazer um orçamento?
  `;

  const encodedMessage = encodeURIComponent(message);
  const whatsappLink = `https://wa.me/${phoneNumber}?text=${encodedMessage}`;

  return (

    <Container>
      <div className="containerInfo">
        <div className="containerImgName">

          <div className="containerNameFollow">
            <h3>Pedido de {service.userName}</h3>
          </div>
        </div>{/*containerImgName */}
        <h2>Serviço: {service.title}</h2>

        <h4>descrição:</h4> <p>{service.description}</p>
        <p><span>Pedido feito em: </span> {service.createdAt && service.createdAt.toDate().toLocaleString()}</p>

        {unlocked === true ?
          <button
            className='unlocked'
          >
            <a href={whatsappLink} target="_blank" rel="noopener noreferrer">
              Enviar mensagem pelo WhatsApp
            </a>
          </button>
          :
          <button
            onClick={() => {
              const userRef = doc(db, "users", user[0].id);
              const relServiceUserRef = collection(db, "relationServicesUsers")

              if (user[0].coins >= service.value) {
                addDoc(relServiceUserRef, {
                  userId: user[0].id,
                  serviceId: service.id,
                  createdAt: serverTimestamp()
                })

                const serviceRef = doc(db, "services", service.id);
                const relServicesRef = collection(db, "services")

                const data = {
                  area: service.area,
                  createdAt: service.createdAt,
                  description: service.description,
                  phoneNumber: service.phoneNumber,
                  title: service.title,
                  unlocked: service.unlocked + 1,
                  userId: service.userId,
                  userName: service.userName,
                  value: service.value
                }

                console.log(data)
                updateDoc(serviceRef, data)

                const userData = {
                  coins: user[0].coins - service.value,
                  createdAt: user[0].createdAt,
                  email: user[0].email,
                  phoneNumber: user[0].phoneNumber,
                  userName: user[0].userName,
                  userType: user[0].userType,
                }

                updateDoc(userRef, userData)
                  .then(docRef => {
                    console.log("A New Document Field has been added to an existing document");
                  })
                  .catch(error => {
                    console.log(error);
                  })
                setUnlocked(true)
              } else {
                setErrorMsg("Você não tem moedas o suficiente")
              }
            }}
          >Desbloquear Pedido <img src={coinImg} alt="" />{service.value}</button>
        }

        {errorMsg && <h4>{errorMsg}</h4>}
      </div>{/*containerInfo*/}
    </Container>
  )
}
