import React, { useContext, useEffect, useState } from 'react'
import { Container } from './styled'
import { auth, db } from '../../config/firebase'
import { addDoc, collection, onSnapshot, query, serverTimestamp, where } from 'firebase/firestore'
import { UserInfoContext } from '../../context/userInfoContext'
import { ClientCard } from '../clientCard/ClientCard'

export const ClientForm = () => {
    const { userInfo, setUserInfo } = useContext(UserInfoContext)

    const [msg, setMsg] = useState()
    //UserName

    const addService = collection(db, "services")

    const [input, setInput] = useState({
        username: "",
        title: "",
        description: "",
        area: "",
    })


    const handleChange = (e) => {
        setInput(prev => ({ ...prev, [e.target.name]: e.target.value }))
    }
// Services



    //Serviços do usuário
    const [services, setServices] = useState([])
    
    useEffect(() => {
        // Verifica se o usuário está definido antes de fazer a consulta
        if (userInfo && userInfo[0].id) {
            const servicesRef = collection(db, "services");
            const findServices = query(servicesRef, where("userId", "==", userInfo[0].id));
            onSnapshot(findServices, (snapshot) => {
                const items = [];
                snapshot.docs.forEach((doc) => {
                    items.push({ ...doc.data(), id: doc.id });
                });
                setServices(items);
            });
        }
    }, [userInfo]); // Adiciona 'user' como dependência do useEffect


    return (
        <Container>
            <div className="form">

                <h2>Informações do Serviço Freelancer</h2>
                <form>
                    <input
                        type="text"
                        id="titulo"
                        name="title"
                        onChange={handleChange}
                        placeholder='Título do Serviço'
                        required
                    />

                    <textarea
                        id="descricao"
                        name="description"
                        rows="4"
                        cols="50"
                        placeholder='Descrição do serviço'
                        onChange={handleChange}
                        required
                    ></textarea>

                    <span>Área de Atuação:</span>
                    <select
                        id="area-atuacao"
                        name="area"
                        onChange={handleChange}
                        required
                    >
                        <option value="">Area do serviço</option>
                        <option value="programacao-sites">Programação de Sites</option>
                        <option value="programacao-aplicativos">Programação de Aplicativos</option>
                        <option value="pedreiro">Serviços de Construção</option>
                        <option value="marcenaria">Marcenaria</option>
                        <option value="ilustracao">Ilustração</option>
                        <option value="design-grafico">Design Gráfico</option>
                        <option value="redacao-conteudo">Redação e Conteúdo</option>
                        <option value="marketing-digital">Marketing Digital</option>
                        <option value="traducao">Tradução</option>
                        <option value="consultoria">Consultoria</option>
                        <option value="suporte-tecnico">Suporte Técnico</option>
                        <option value="video-edicao">Edição de Vídeo</option>
                    </select>

                    <button
                        onClick={(e) => {
                            e.preventDefault()
                            addDoc(addService, {
                                area: input.area,
                                createdAt: serverTimestamp(),
                                description: input.description,
                                title: input.title,
                                userName: userInfo[0].userName,
                                img: userInfo[0].img,
                                value: 50,
                                phoneNumber: userInfo[0].phoneNumber,
                                userId: userInfo[0].id,
                                unlocked: 0,
                            })
                            setMsg("Seu serviço foi adicionado. Aguarde até que um profissional te contacte")
                        }}
                    >Enviar</button>
                </form>
                {msg && <h3>{msg}</h3>}

            </div>{/*form */}

            <div className="containerMyServices">
                <h3>Seus pedidos!</h3>

                <div className="containerServices">
                    {

                        services.map((item) => {
                            return (
                                <div
                                    key={item.id}
                                    className='containerSingleService'
                                >
                                    <ClientCard item={item}/>
                                </div>/*containerSingleService */
                            )
                        })
                    }
                </div>{/*containerServices*/}
            </div>{/*containerMyServices*/}
        </Container>
    )
}
