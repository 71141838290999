import React from 'react'
import { Container } from './style'

export const Contact = () => {
  return (
    <Container>
      <h2>Fale conosco</h2>
      <form action="">
        <textarea
          placeholder='Escreva aqui a sua mensagem'
          name=""
          id=""
          cols="30"
          rows="10"
        ></textarea>
        <button>enviar</button>
      </form>
      
    </Container>
  )
}
