import styled from "styled-components"

export const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 10px;
    .containerLogo{
        display: flex;
        align-items: center;
        h1{
            font-weight: 900;
        }
    }
    .form{
        padding: 15px;
        border-radius: 5px;
        background-color: #fff;
        box-shadow: 0 0 10px #ccc;
        form{
            display: flex;
            flex-direction: column;
            gap: 10px;
            margin-top: 10px;
            input{
                border: 0;
                background-color: #eee;
                border-radius: 5px;
                padding: 5px;
            }
            button{
                border: 0;
                padding: 15px;
                background-color: rgb(0, 89, 255);
                color: #fff;
                border-radius: 5px;
                font-weight: bold;
                font-size: 20px;
                cursor: pointer;
            }
        }
        .errMsg{
            margin-top: 20px;
            span{
                font-weight: 900;
            }
        }
    }

    
    p{
        font-weight: bold;
        color:#000;
        a{
            color: rgb(0, 119, 255);
            text-decoration: none;
        }
        a:hover{
            text-decoration: underline;
        }
    }
`
