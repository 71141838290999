import React from 'react'
import { Container } from './style'

export const SecurityTerms = () => {
    return (
        <Container>
            <p className='welcome'>
                <strong>Bem-vindo ao Freelaverse!</strong> Ao utilizar nossos serviços, você concorda com os termos descritos abaixo. Certifique-se de ler com atenção antes de prosseguir.

            </p>
            <div className="containerItem">

                <h3>1. Aceitação dos Termos</h3>

                <p>Ao se cadastrar e utilizar os serviços oferecidos pelo Freelaverse, você concorda plenamente com estes Termos de Serviço. Caso não concorde com qualquer parte deste documento, pedimos que interrompa o uso dos nossos serviços.</p>

            </div>{/*containerItem */}
            
            <div className="containerItem">
                <h3>2. Cadastro e Contas</h3>

                <p>2.1. O Freelaverse oferece duas opções de cadastro: Cliente e Profissional. Os usuários devem escolher a opção mais adequada ao seu perfil e área de atuação.</p>

                <p>2.2. O cadastramento requer informações precisas e verdadeiras. Você é responsável por manter suas informações atualizadas e por garantir a confidencialidade de sua senha e conta.</p>
            </div>{/*containerItem */}

            <div className="containerItem">
                <h3>3. Funcionamento da Plataforma</h3>

                <p> 3.1. Clientes podem cadastrar serviços que necessitam de profissionais de áreas específicas. Profissionais visualizarão os pedidos relacionados à sua área de atuação.</p>

                <p>3.2. A plataforma não se responsabiliza por qualquer acordo ou contrato realizado entre clientes e profissionais. O Freelaverse age apenas como intermediário na conexão entre as partes.</p>

                <p>3.3. O Freelaverse não assume responsabilidade por eventuais disputas, atrasos, danos ou insatisfações decorrentes dos acordos firmados entre usuários.</p>
            </div>{/*containerItem */}

            <div className="containerItem">
                <h3>4. Compra de Pontos e Contato com Clientes</h3>

                <p>4.1. Profissionais devem comprar pontos na plataforma para desbloquear o contato com clientes. Cada cliente custa 50 pontos.</p>

                <p>4.2. Após adquirir os pontos, o profissional terá acesso aos dados de contato do cliente (como WhatsApp ou número de telefone) para iniciar a comunicação.</p>

                <p>4.3. Os pontos utilizados para liberar um cliente não serão reembolsados em caso de cancelamento por parte do cliente.</p>
            </div>{/*containerItem */}
            
            <div className="containerItem">
                <h3>5. Responsabilidade e Acordos</h3>

                <p>5.1. O Freelaverse é responsável somente por facilitar a conexão entre profissionais e clientes. O processo de execução do serviço, acordos e contratos são de total responsabilidade das partes envolvidas.</p>

                <p>5.2. Recomendamos que os usuários firmem acordos por escrito e estabeleçam termos claros para evitar mal-entendidos.</p>
            </div>{/*containerItem */}

            <div className="containerItem">
                <h3>6. Intuito da Plataforma</h3>

                <p>6.1. O Freelaverse tem como objetivo principal juntar clientes e profissionais. Não nos responsabilizamos por etapas subsequentes ao contato inicial entre as partes.</p>
            </div>{/*containerItem */}
            
            <div className="containerItem">
                <h3>7. Alterações nos Termos</h3>

                <p>7.1. O Freelaverse reserva-se o direito de modificar esses Termos de Serviço a qualquer momento. Quaisquer alterações serão comunicadas aos usuários, e o uso contínuo dos serviços após as modificações representa a aceitação dos novos termos.</p>

                <p>Ao utilizar o Freelaverse, você concorda em respeitar e cumprir estes termos. Esperamos que tenha uma experiência positiva em nossa plataforma, unindo clientes e profissionais em busca de soluções eficientes.</p>
            </div>{/*containerItem */}
            
            <div className="containerItem">
                <h3>8. Isenção de Responsabilidade por Crimes e Recomendações de Segurança</h3>

                <p>8.1. O Freelaverse não assume qualquer responsabilidade por crimes cometidos por clientes ou profissionais. Isso inclui, mas não se limita a, roubos, fraudes, estelionato ou qualquer outro ato ilícito praticado por qualquer uma das partes.</p>

                <p>8.2. Recomendamos fortemente que todas as partes envolvidas estejam sempre cientes dos riscos potenciais e adotem medidas preventivas para garantir sua própria segurança.</p>

                <p>8.3. Os usuários são incentivados a verificar cuidadosamente a identidade e histórico do profissional ou cliente com quem pretendem estabelecer contato, bem como a buscar referências e outras informações relevantes antes de iniciar qualquer acordo.</p>

                <p>8.4. Caso ocorram situações suspeitas ou incidentes de qualquer natureza, os usuários devem relatar imediatamente às autoridades competentes e cooperar na investigação.</p>
            </div>{/*containerItem */}

            <div className="containerItem">
                <h3>9. Indenização</h3>

                <p>9.1. Os usuários concordam em indenizar e isentar o Freelaverse, suas afiliadas, diretores, funcionários e agentes de qualquer responsabilidade decorrente de quaisquer reclamações, ações judiciais, perdas, danos, custos e despesas (incluindo honorários advocatícios) relacionados ao uso da plataforma ou ao não cumprimento destes termos.</p>

                <p>O Freelaverse reforça a importância da vigilância, cautela e comunicação aberta entre todas as partes envolvidas. A plataforma está comprometida em fornecer um ambiente seguro para facilitar a conexão entre clientes e profissionais, mas não pode garantir a conduta individual ou prevenir todos os riscos.</p>
            </div>{/*containerItem */}
        </Container>
    )
}
