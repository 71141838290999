import React, { useContext, useState } from 'react'
import { Container } from './style'
import { auth, db } from '../../../config/firebase'
import { signOut } from 'firebase/auth'
import { collection, onSnapshot, query, where } from 'firebase/firestore'
import { BiMenu } from "react-icons/bi";
import coinImg from "../../../img/coin.png"
import imgLogo from "../../../img/logores.png"
import { useNavigate } from 'react-router-dom'
import { PaymentViewContext } from '../../../context/paymentViewContext'
import { DefaultView } from '../../../pages/getCoins/coinView'
import { UserInfoContext } from '../../../context/userInfoContext'


export const Header = () => {

    const { userInfo, setUserInfo } = useContext(UserInfoContext)

    const [menuMobile, setMenuMobile] = useState(false)
    const navigate = useNavigate()
    const { paymentView, setPaymentView } = useContext(PaymentViewContext)


    return (
        <Container>
            <div className="containerSizeHeader">
                <div className="containerLeftHeader">
                    <div className="containerLogoName">
                        <div className="logo" onClick={() => {
                            navigate("/")
                        }}>
                            <h1>FreelaVerse</h1>
                            <img src={imgLogo} alt="" />
                        </div>{/*logo*/}
                        <h2>Seja bem vindo {userInfo && userInfo[0]?.userName}</h2>
                    </div>{/*containerLogoName */}

                </div>{/*containerLeftHeader*/}

                <div className="containerRightMenu">
                    {userInfo && userInfo.length > 0 && userInfo[0].userType === "professional" && (
                        <button
                            className="containerCoins"
                            onClick={() => {

                                setPaymentView(<DefaultView />)
                                navigate("/getCoins")
                            }}
                        >
                            <img src={coinImg} alt="" />
                            {userInfo && userInfo[0]?.coins}
                        </button>/*containerCoins*/
                    )}

                    {userInfo && userInfo.length > 0 && userInfo[0].userType === "client" && (
                        <>
                            <button
                                className='exitButton'
                                onClick={() => {
                                    navigate("/notifications")
                                }}
                            >Notificações</button>

                            <button
                                className='exitButton'
                                onClick={() => {
                                    navigate("/contact")
                                }}
                            >Fale Conosco</button>
                            <button
                                className='exitButton'
                                onClick={() => {
                                    signOut(auth)
                                        .then(() => console.log("signOut Success"))
                                        .catch((err) => { console.log("erro: ", err.message) })
                                }}
                            >Sair</button>


                        </>

                    )}

                    <div className="containerMobileMenu">
                        <button onClick={() => { setMenuMobile(!menuMobile) }}>
                            <BiMenu />
                        </button>

                        {menuMobile &&
                            <div className="containerMenuMobileItems">
                                <ul>
                                    {/*<li onClick={() => navigate("/myProfile")}>Perfil</li>*/}
                                    {userInfo && userInfo.length > 0 && userInfo[0].userType === "professional" && (
                                        <>
                                            <li onClick={() => {
                                                navigate("/myservices")
                                            }}>Pedidos Liberados</li>

                                            <li onClick={() => navigate("/specialties")}>Minhas Áreas de Atuação</li>
                                        </>
                                    )}

                                    <li onClick={() => navigate("/notifications")}>Notificações</li>


                                    <li onClick={() => navigate("/contact")}>Fale Conosco</li>

                                    <li onClick={() => {
                                        navigate("/login")
                                        signOut(auth)
                                            .then(() => console.log("signOut Success"))
                                            .catch((err) => { console.log("erro: ", err.message) })
                                    }}
                                    >Sair</li>
                                </ul>
                            </div>/*containerMenuMobileItems*/
                        }

                    </div>{/*containerMobileMenu */}

                </div>{/*containerRightMenu */}

            </div>{/*containerSizeHeader */}
        </Container>
    )
}
