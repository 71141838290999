import styled from "styled-components"

export const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 10px;
    .containerLogo{
        display: flex;
        align-items: center;
        h1{
            font-weight: 900;
        }
    }
    .form{
        padding: 15px;
        border-radius: 5px;
        background-color: #fff;
        box-shadow: 0 0 10px #ccc;
        form{
            display: flex;
            flex-direction: column;
            gap: 10px;
            margin-top: 10px;
            input{
                border: 0;
                background-color: #eee;
                border-radius: 5px;
                padding: 5px;
            }
            button{
                border: 0;
                padding: 15px;
                background-color: rgb(0, 89, 255);
                color: #fff;
                border-radius: 5px;
                font-weight: bold;
                font-size: 20px;
                cursor: pointer;
            }

            .containerUserType{
                display: flex;
                input{
                    display: none;
                }

                input:checked + label{
                    color: #fff;
                    background-color: #ffc400;
                }

                input:checked + label::before{
                    height: 15px;
                    width: 15px;
                    border: solid 5px #fff;
                }

                label{
                    position: relative;
                    cursor: pointer;
                    color: #ffc400;
                    padding: 10px 15px;
                    margin: 5px;
                    font-size: 20px;
                    border: 2px solid #ffc400;
                    padding: 5px;
                    border-radius: 5px;
                    display: flex;
                    align-items: center;
                }

                label::before{
                    content:"";
                    height: 15px;
                    width: 15px;
                    border: solid #ffc400 3px;
                    border-radius: 50px;
                    margin-right: 10px;
                }
            }

            .ageField{
                margin: 10px 0;
                label{
                    padding-left: 10px;
                }
            }
        }
    }

    p{
        font-weight: bold;
        color:#000;
        a{
            color: rgb(0, 119, 255);
            text-decoration: none;
        }
        a:hover{
            text-decoration: underline;
        }
    }

    .terms{
        max-width: 400px;
        text-align: center;
        a{
            text-decoration: none;
        }
    }
`
