import styled from "styled-components"

export const Container = styled.div`

    position: fixed;
    background-color: #fff;
    width: 100%;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 20px;
    border-bottom: solid 1px #ccc;

    
    .containerSizeHeader{
        width: 100%;
        max-width: 1400px;
        align-items: center;
        justify-content: space-between;
        display: flex;

    }

    .containerLeftHeader{
        display: flex;
        align-items: center;
        gap: 30px;
    }

    .containerLogoName{
        display: flex;
        flex-direction: column;
        .logo{
            display: flex;
            align-items: center;
            cursor: pointer;
            h1{
                @media screen and (max-width:508px){
                    font-size: 20px;
                }
                font-weight: 900;
            }
            img{
                @media screen and (max-width:508px){
                    width : 40px;
                }
                width : 50px;
                object-fit: contain;
            }
        }
        h2{
            font-size: 10px;
            font-weight: 900;
            text-transform: uppercase;
            color: rgb(0, 119, 255);
            text-align: center;
            margin-top:-5px ;
        }
    }
    .containerRightMenu{
        display: flex;
        gap: 30px;
        align-items: center;
        justify-content: center;
        @media (max-width: 768px) {
            gap: 10px;
        }
        
        button.containerCoins{
            display: flex;
            align-items: center;
            font-weight: 900;
            font-size: 20px;
            border: solid 1px #ccc;
            border-radius: 30px;
            padding-right: 30px;
            cursor: pointer;
            img{
                width: 50px;  
                @media screen and (max-width:508px){
                    width : 30px;
                }
            }
        }

    }

    button{
        border: none;
        padding: 5px 15px;
        font-size: 20px;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 5px;
        color: #fff;
        background-color: rgb(26, 20, 24);
        transition: .4s;
        cursor: pointer;
        @media screen and (max-width:508px){
            padding: 5px;
        }
    }

    button:hover{
        background-color: hsl(352, 69%, 51%);
    }

    .exitButton{
        @media (max-width: 768px) {
            display: none;
        }
    }

    .containerMobileMenu{
        position: relative;
        display: none;
        @media (max-width: 768px) {
            display: flex;
        }

        button{
            height: 100%;
            font-size: 30px;
            cursor: pointer;
        }
        .containerMenuMobileItems{
            position: absolute;
            width: 200px;
            background-color: #fff;
            border: solid 1px #ccc;
            border-radius: 5px;
            font-size: 20px;
            font-weight: 900;
            right: 0;
            top: 52px;
            transition: ease-in-out 1s;
            li{
                border-bottom: solid 1px #ccc;
                padding: 25px;
            }
            li:hover{
                background-color: #ddd;
                cursor: pointer;
            }
        }
    }
    

    

`
