import styled from "styled-components"

export const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .containerImgName{
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 30px;
        .containerProfileImg{
            width: 170px;
            height: 170px;
            img{
                width: 170px;
                height: 170px;
                object-fit: cover;
                border-radius: 170px;
                border: solid 5px #ccc;
            }
        }

        .containerNameFollow{
            h3{

                font-weight: 900;
                font-size: 25px;
            }
            text-transform: uppercase;
        }
    }
    h2{
        border-top: solid 1px #ccc;
        margin-top: 20px;
        padding-top: 20px;
        font-weight: 900;
        font-size: 30px;
        margin-bottom: 30px;
    }
    h4{
        font-weight: 900;
        font-size: 20px;
        text-transform: uppercase;
    }
    .containerInfo{
        height: 100%;
        padding: 20px;

        span{
            font-weight: 900;
        }

        button.unlocked{
            a{
                color: #fff;
                text-transform: uppercase;
                text-decoration: none;
                padding: 10px;
            }
            transition: ease-in-out .2s;
        }

        button.unlocked:hover{
            background-color: hsl(352, 69%, 51%);
        }

        button{
            margin-top: 20px;
            border: 0;
            background-color: #000;
            color: #fff;
            padding: 0 15px;
            border-radius: 15px;
            font-size: 20px;
            font-weight: 900;
            display: flex;
            align-items: center;
            cursor: pointer;
            img{
                width: 60px;
            }
        }
    }
`