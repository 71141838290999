import styled from "styled-components"

export const Container = styled.div`
    @media screen and (max-width:768px){
        display: none;
    }
    position: fixed;
    height: 100%;
    display: flex;
    top: 70px;
    flex-direction: column;
    gap: 15px;
    padding: 15px;
    button{
        border: 0;
        cursor: pointer;
        color: #000;
        font-weight: 900;
        text-transform: uppercase;
        font-size: 14px;
        display: flex;
        border-radius: 5px;
        border: solid 1px #ccc;
        background-color: #fff;
        padding: 10px;
        text-align: left;
        align-items: center;
        gap: 10px;
        transition: ease-in-out .2s;
    }

    button:hover{
        background-color: #000;
        color:#fff;
    }

`