import React, { useEffect, useState } from 'react'
import { AiFillCaretLeft } from "react-icons/ai"
import imgLogo from "../../img/logo1.png"
import { Container } from './style'
import { useNavigate } from 'react-router-dom'

export const SelectAreaService = () => {
    const [areaServiceView, setAreaServiceView] = useState("")
    const navigate = useNavigate()

    const Instructions = ({ type }) => {
        if (type === "client") {
            return (

                <>
                    <button className='btnBack' onClick={() => {
                        setAreaServiceView(<UserType />)
                    }}>
                        <AiFillCaretLeft />
                        voltar
                    </button>
                    <div className="containerInstructions">
                        <p className='msgInfo'>
                            Continue e cadastre-se na plataforma, inscreva o seu pedido e um profissional irá entrar em contato!
                        </p>
                        <button className='btnContinue' onClick={()=>{navigate("/register")}}>Continuar</button>

                    </div>{/*containerInstructions*/}
                </>
            )
        } else {
            return (
                <>
                    <button className='btnBack' onClick={() => {
                        setAreaServiceView(<UserType />)
                    }}>
                        <AiFillCaretLeft />
                        voltar
                    </button>
                    <div className="containerInstructions">
                        <p className='msgInfo'>
                            Continue e cadastre-se na plataforma, você tem um universo de clientes te esperando!
                        </p>
                        <button className='btnContinue' onClick={()=>{navigate("/register")}}>Continuar</button>
                    </div>{/*containerInstructions*/}
                </>
            )
        }
    }
    const UserType = () => {
        return (
            <>
                <div className="containerText">
                    <h2>O que você procura?</h2>
                </div>
                <div className="containerChoose">
                    <div className='btnChoose' onClick={() => {
                        setAreaServiceView(<Instructions type="client" />)
                    }}>
                        Procuro por um profissional
                    </div>
                    <div className='btnChoose' onClick={() => {
                        setAreaServiceView(<Instructions type="professional" />)
                    }}>
                        Sou um profissional
                    </div>
                </div>{/*containerChoose*/}
            </>
        )
    }

    useEffect(() => {
        setAreaServiceView(<UserType />)
    }, [])

    return (
        <Container>
            <div className="header">
                <div className="logo">
                    <h1>FreelaVerse</h1>
                    <img src={imgLogo} alt="" />
                </div>{/*logo*/}
            </div>{/*containerHeader */}
            <div className="content">
                <div className="boxScreen">
                    {areaServiceView}
                </div>{/*BoxScreen*/}
            </div>
        </Container>/*container*/
    )
}