import styled from "styled-components"

export const Container = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    h2{
        font-weight: 900;
        margin: 20px 0;
    }
    form{
        width: 100%;
        max-width: 1200px;
        padding: 20px;
        label{
            text-transform: uppercase;
            margin: 10px 0;
            font-weight: 900;
        }
        display: flex;
        flex-direction: column;

        textarea{
            border: #ddd solid 1px;
            width: 100%;
            max-width: 1200px;
            padding: 10px;
            font-size: 18px;
            resize: vertical;
            font-weight: 900;
        }

        textarea::placeholder{
            color:#aaa
        }

        button{
            margin-top: 20px;
            padding: 20px;
            font-weight: 900;
            font-size: 18px;
            text-transform: uppercase;
            border: 0;
            background-color: #000;
            color: #fff;
            border: 0;
            border-radius: 5px;
            cursor: pointer;
            transition: ease-in-out .2s;
        }
        button:hover{
            background-color: hsl(352, 69%, 51%);
            
        }
    }
`