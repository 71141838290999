import styled from "styled-components"

export const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .containerPackages{
        padding: 20px;
        display: flex;
        gap: 20px;
        max-width: 1200px;
        
        @media (max-width: 768px) {
            flex-direction: column;
        }
        .containerSinglePackage{
            width: 33%;
        
            padding: 20px;
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            border: solid 1px #ccc;
            border-radius: 15px;
            box-shadow: 0 0 10px #777;
            gap:20px;

            @media (max-width: 768px) {
                width: 100%;
            }
            
            img{
                border-radius: 20px;
                width: 100%;
                height: 300px;
                object-fit: cover;
            }

            h3{
                font-weight: 900;
                text-transform: uppercase;
                font-size: 20px;
            }

            button{
                border: 0;
                font-weight: 900;
                color: #fff;
                background-color: #000;
                padding: 10px 20px;
                text-transform: uppercase;
                cursor: pointer;
                transition: ease-in-out .4s;
            }

            button:hover{
                background-color: hsl(352, 69%, 51%);
            }
        }
    }
`