import styled from "styled-components"

export const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    .containerUserInfo{
        width: 100%;
        padding: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        border-bottom:solid 1px #ccc ;
        img{
            width: 180px;
            border-radius: 90px;
            border: solid 2px rgb(0, 119, 255);
        }

        .containerTexts{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 5px;
            .description{
                max-width: 200px;
                text-align: center;
                margin-bottom: 10px;
            }
            .social{
                display: flex;
                gap: 10px;
                img{
                    width: 30px;
                    height: 30px;
                    object-fit: cover;
                    border: 0;
                    cursor: pointer;
                }
            }
        }
    }

    .containerPosts{
        width: 100%;
        max-width: 1200px;
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
        
        .containerSinglePost{
            width: calc(33% - 20px);
            height: 300px;
            margin: 10px;
            min-width: 250px;
            border-radius: 10px;
            border: solid 1px #ccc;
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 10px;
            }
            @media (max-width: 768px) {
                width: 100%;
                margin: 20px;
            }
        }
    }
`