import styled from "styled-components"

export const Container = styled.div`
width: 100%;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
gap: 20px;
padding-top: 40px;
h2{
    text-transform: uppercase;
    font-size: 23px;
    font-weight: 900;
}
p{
    color: #777;
}
.containerBoletoCode{
    border: solid 1px #ccc;
    padding: 50px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    h3{
        font-weight: 900;
        font-size: 20px;
    }
    span{
        max-width: 500px;
        font-weight: bold;
        font-size: 20px;
    }
    input{
        border: #ccc solid 1px;
        padding: 10px;
        font-weight: bold;
        font-size: 20px;
    }
    .containerBoleto{
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: center;
        input{
            width: 100%;
        }
        button.boletoCopy{
            background-color: rgb(38, 160, 74);
            font-weight: 900;
            font-size: 30px;
            text-transform: uppercase;
            padding: 10px;
            border-radius: 5px;
            border: 0;
            cursor: pointer;
            transition: ease-in-out .2s;
            color: #fff;
        }
        button.boletoCopy:hover{
            background-color: rgb(34, 197, 83);
        }


        .pdfBoleto{
            width: 200px;
            padding: 20px;
            border: 0;
            font-weight: 900;
            font-size: 20px;
            border-radius: 5px;
            background-color: rgb(216, 44, 67);
            color: #fff;
            cursor: pointer;
            text-align: center;
            text-transform: uppercase;
            text-decoration: none;
        }

        
    }

    form{
        display: flex;
        flex-direction: column;
        gap: 10px;
        button.generateBoleto{
            border: 0;
            background-color: #000;
            padding: 10px;
            font-size: 20px;
            font-weight: 900;
            text-transform: uppercase;
            cursor: pointer;
            border-radius: 5px;
            color: #fff;
        }
    }
    
}

`