import React from 'react'
import { Container } from './style'

export const OtherProfile = () => {
  return (
    <Container>
        
    </Container>
  )
}
