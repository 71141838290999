import React, { useContext, useEffect, useState } from 'react'
import { Container } from './style'
import { PaymentViewContext } from '../../context/paymentViewContext'
import { useNavigate } from 'react-router-dom'

export const GetCoins = () => {
  const {paymentView, setPaymentView} = useContext(PaymentViewContext)
  const navigate = useNavigate()
 
  useEffect(()=>{
    if(paymentView === undefined){

      navigate("/")
    }
  },[paymentView])
  return (
    <>
      <Container>
        
        {paymentView}
          
        
      </Container>
    </>
  )
}
