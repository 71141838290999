import React, { useContext, useEffect, useState } from 'react'
import { Container } from './style'
import { auth, db } from '../../config/firebase'
import { collection, onSnapshot, query, where } from 'firebase/firestore'
import { Cards } from '../home/cards/Cards'
import { ServiceViewContext } from '../../context/serviceViewContext'
import { useNavigate } from 'react-router-dom'

export const List = () => {

  const navigate = useNavigate()

  //Context
  const { service, setService } = useContext(ServiceViewContext)

  // Services
  const colItemsRef = collection(db, "services");
  const findServices = query(colItemsRef);
  const [filteredServices, setFilteredServices] = useState([]);
  const [services, setServices] = useState([]);

  // UserId
  const userRef = collection(db, "users");
  const { currentUser } = auth;
  const findUserId = query(userRef, where("email", "==", currentUser.email));
  const [userId, setUserId] = useState();
  const [user, setUser] = useState();

  // RelationUserServices
  const relationRef = collection(db, "relationServicesUsers");

  useEffect(() => {
    // Fetch services
    onSnapshot(findServices, (snapshot) => {
      const items = [];
      snapshot.docs.forEach((doc) => {
        items.push({ ...doc.data(), id: doc.id });
      });
      setServices(items);
    });

    // Fetch user ID
    onSnapshot(findUserId, (snapshot) => {
      let users = [];
      snapshot.docs.forEach((doc) => {
        users.push({ ...doc.data(), id: doc.id });
      });
      setUserId(users[0].id);
      setUser(users)
    });
  }, [userId]);

  const [next, setNext] = useState()

  useEffect(() => {
    // Fetch and filter user services
    if (userId !== undefined) {
      const findRelUserService = query(relationRef, where("userId", "==", userId));
      onSnapshot(findRelUserService, (snapshot) => {
        let relUsersServices = [];
        snapshot.docs.forEach((doc) => {
          relUsersServices.push({ ...doc.data(), id: doc.id });
        });

        // Filter services based on user ID
        const nonUserServices = services.filter(
          (item) => !relUsersServices.some((relItem) => item.id === relItem.serviceId)
        );

        // Filter services based on user specialties
        const userSpecialties = user[0]?.specialties;
        if (userSpecialties && userSpecialties.length > 0) {
          const userServices = nonUserServices.filter((item) =>
            userSpecialties.includes(item.area)
          );

          const limitUnlocked = userServices.filter(
            (item) => !userServices.some((unlockedItem) => item.unlocked === 2)
          );

          setFilteredServices(limitUnlocked);
        } else {
          setFilteredServices(nonUserServices);
        }
      });
    }
  }, [userId, user, services]);

  return (
    <Container>
      <div className="containerServicesList">
        {
          filteredServices.length > 0 ?
            filteredServices.map((item) => {
              return (
                <div
                  className="containerCard"
                  key={item.id}
                  onClick={() => {
                    setService(item)
                    navigate("/service")
                  }}
                >
                  <Cards item={item} />
                </div>
              )
            })
            : (
              <div className="containerEmptyList">
                <h3>Não há pedidos no momento! Aguarde novos pedidos chegarem!</h3>
              </div>/*containerEmptyList*/
            )
        }
      </div>{/*containerServicesList */}
    </Container>
  )
}
