import styled from "styled-components"

export const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 50px;
    gap: 10px;
    text-align: left;
    .welcome{
        font-size: 20px;
    }
    .containerItem{
        h3{
            font-size: 20px;
            font-weight: 900;
        }
        p{
            font-size: 18px;
            
        }
    }
`