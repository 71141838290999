import React, { useContext, useEffect, useState } from 'react'
import { Container } from './style'
import axios from "axios"
import { Link } from 'react-router-dom'
import { UserInfoContext } from '../../../context/userInfoContext'

const PixScreen = ({ price, quantity }) => {
  const [copiedPix, setCopiedPix] = useState(false)
  const [pixCode, setPixCode] = useState()
  const { userInfo, setUserInfo } = useContext(UserInfoContext)

  function getFutureDateWithThreeDays() {
    // Obtém a data atual
    const currentDate = new Date();

    // Acrescenta 3 dias à data atual
    currentDate.setDate(currentDate.getDate() + 3);

    // Formata a data no formato ISO 8601
    const formattedDate = currentDate.toISOString();

    return formattedDate;
  }


  const getQRCode = async () => {
    console.log(price)
    const info = {
      name: userInfo[0].userName,
      email: userInfo[0].email,
      quantity: quantity, 
      price: price * 100,
      expirationDate: getFutureDateWithThreeDays()
    }
    console.log("Lista de parâmetros: ",info)
    const res = await axios.post("https://server-piclobby.vercel.app/api/PixController", {
      name: userInfo[0].userName,
      email: userInfo[0].email,
      quantity: quantity, 
      price: price * 100,
      expirationDate: getFutureDateWithThreeDays()
    })
      .then((res) => {
        setPixCode(res.data);
        setCopiedPix(true);
      })
      .catch((error) => {
        console.error('Erro ao obter o código PIX', error);
      });
  };

  useEffect(() => {
    getQRCode();
  }, []); // O array vazio como segundo argumento faz com que o useEffect seja executado apenas uma vez, na montagem do componente


  const handleClick = () => {
    // Tenta copiar o código para a área de transferência
    try {
      navigator.clipboard.writeText(pixCode[0]);
      setCopiedPix(true);
    } catch (err) {
      console.error('Falha ao copiar o código PIX', err);
    }
  };

  return (
    <Container>
      <h2>A sua compra está quase finalizada!</h2>
      <h3>Forma de pagamento: PIX</h3>
      <div className="containerPixCode">
        <h3>Valor do pacote: {price}</h3>
        <span>Clique no botão abaixo e aguarde que o código pix será copiado</span>

        {copiedPix ?
          <>
            <button className='pixCopy' onClick={handleClick}>
              Copiar código PIX
            </button>

            <Link className='qrcode' to={pixCode[1]}>Abrir QRCODE</Link>
          </>
          :
          <>
            <button className='pixCopy'>
              Aguarde...
            </button>

            <button className='qrcode'>
              Aguarde...
            </button>
          </>
        }

        <p>Assim que o pagamento for compensado você receberá as suas moedas!</p>
      </div>{/*containerPixCode*/}
    </Container>
  )
}

export default PixScreen;
