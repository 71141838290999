import React from 'react'
import { Container } from './style'

export const Notifications = () => {
  return (
    <Container>
        <h2>Em Construção</h2>
    </Container>
  )
}
