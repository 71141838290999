import React from 'react'
import { Container } from './style'
import { BiExit, BiSolidUser } from 'react-icons/bi'
import { useNavigate } from 'react-router-dom'
import { auth } from '../../config/firebase'
import { signOut } from 'firebase/auth'

export const Aside = () => {
  
const navigate = useNavigate()
  
  return (
    <Container>
        {/*<button onClick={()=>{
            navigate("/myProfile")
        }}><BiSolidUser size={20}/> Perfil</button>
        */}
        <button onClick={()=>{
            navigate("/myservices")
        }}>Pedidos liberados</button>

        <button onClick={()=>{
            navigate("/notifications")
        }}>Notificações</button>

        <button onClick={()=>{
            navigate("/specialties")
        }}>Minhas áreas de atuação</button>

        <button onClick={()=>{
          navigate("/contact")
        }}>Fale Conosco</button>

        <button onClick={()=>{
            signOut(auth)
                .then(() =>{
                  console.log("signOut Success")
                  navigate("/login")
                })
                .catch((err) => { console.log("erro: ", err.message) })
            
        }}><BiExit size={20}/> Sair</button>
        
    </Container>
  )
}
