import styled from "styled-components"

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    padding: 2%;
    border: solid 1px #ccc;
    border-radius: 5px;
    background-color: #eee;
    border: 0;
    box-shadow: 0 0 5px #777;
    max-width: 1200px;
    span.area{
        font-weight: 900;
        text-transform: uppercase;
        font-size: 20px;
    }

    p{
        color: #777;
    }

    .containerUserData{
        display: flex;
        justify-content: space-between;
        align-items: center;
        .username{
            font-weight: 900;
            font-size: 18px;
            color: #111;
        }
    }

    .containerTitleDelete{
        display: flex;
        .area{
            flex: 5;
            font-size: 18px;
        }

        .btnDelete{
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            button{
                width: 50px;
                height: 50px;
                border: 0;
                background-color: rgb(216, 44, 67);
                font-size: 18px;
                border-radius: 10px;
                font-weight: 900;
                color: #fff;
                cursor: pointer;
            }
        }
    }
`