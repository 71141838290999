import styled from "styled-components"

export const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 30px;
    justify-content: center;
    align-items: center;
    
    .form{
        width: 70%;
        height: 100%;
        box-shadow: 0 0 10px #ccc;
        padding: 30px;
    }

    h2{
        font-weight: 900;
    }
    form{
        width: 100%;
        display: flex;
        justify-content: center;
        span{
            font-size: 20px;
            font-weight: 900;
        }
        flex-direction: column;

        input, select, textarea{
            width: 100%;
            border: 0;
            background-color: #ccc;
            padding: 5px;
            border-radius: 5px;
            margin: 5px;
        }
        textarea{
            resize: vertical;
        }
        button{
            max-width: 250px;
            border: 0;
            background-color: rgb(216, 44, 67);
            padding: 10px 15px;
            font-size: 20px;
            font-weight: 900;
            border-radius: 15px;
            color: #fff;
            cursor: pointer;
            margin-top: 20px;
        }
    }

    .containerMyServices{
        margin-top:20px;

        .containerServices{

            padding: 10px;
            max-height: 350px;
            overflow-y: scroll;
        }
        h3{
            font-weight: 900;
            font-size: 20px;
            text-transform: uppercase;
        }
        .containerSingleService{
            margin-top: 20px;
        }
        
    }
`
