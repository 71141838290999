import React, { useContext } from 'react'
import { PaymentViewContext } from '../../context/paymentViewContext'
import { PaymentOptions } from './paymentOptions/PaymentOptions'


export const DefaultView = () => {
    
    const {paymentView, setPaymentView} = useContext(PaymentViewContext)

    return (
        <>
            <div className="containerPackages">
                <div className="containerSinglePackage">

                    <img src="https://i.pinimg.com/564x/f6/4e/91/f64e91a3c88bf4322779222be1cfd201.jpg" alt="" />
                    <div className="containerText">
                        <h3>Pacote Básico</h3>
                        <p>Pacote com 1200 moedas</p>
                    </div>{/*containerText */}
                    <button
                        onClick={()=>{
                            setPaymentView(<PaymentOptions price={100} quantity={1200}/>)
                        }}
                    >Comprar</button>
                    <h3>R$100,00</h3>
                </div>{/*containerSinglePackage */}

                <div className="containerSinglePackage">
                    <img src="https://i.pinimg.com/564x/d4/39/6e/d4396ee55f55a3c6eb5298db47339a3a.jpg" alt="" />
                    <div className="containerText">
                        <h3>Pacote Padrão</h3>
                        <p>Pacote com 2400 moedas</p>
                    </div>{/*containerText */}
                    <button
                        onClick={()=>{
                            setPaymentView(<PaymentOptions price={180} quantity={2400}/>)
                        }}
                    >Comprar</button>
                    <h3>R$180,00</h3>
                </div>{/*containerSinglePackage */}


                <div className="containerSinglePackage">
                    <img src="https://i.pinimg.com/564x/79/58/20/7958200c022fd8c7f1daea4bc9b807c2.jpg" alt="" />
                    <div className="containerText">
                        <h3>Pacote Premium</h3>
                        <p>Pacote com 4000 moedas</p>
                    </div>{/*containerText */}
                    <button
                        onClick={()=>{
                            setPaymentView(<PaymentOptions price={280} quantity={4000}/>)
                        }}
                    >Comprar</button>
                    <h3>R$280,00</h3>
                </div>{/*containerSinglePackage */}

            </div>{/*containerPackages*/}
        </>
    )
}
