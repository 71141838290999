import { useContext, useEffect, useState } from "react";
import { AuthenticatedUserContext, AuthenticatedUserProvider } from "./context/AuthContext";
import { onAuthStateChanged } from "firebase/auth";
import { Navigate, Outlet, RouterProvider, createBrowserRouter } from "react-router-dom";
import GlobalStyle from "./global"
import { Home } from "./pages/home/Home";
import { Profile } from "./pages/profile/Profile";
import { Login } from "./pages/login/Login";
import { auth } from "./config/firebase";
import { Register } from "./pages/register/Register";
import { InfoService } from "./pages/infoService/InfoService";
import { ServiceViewProvider } from "./context/serviceViewContext";
import { GetCoins } from "./pages/getCoins/GetCoins";
import { PaymentViewProvider } from "./context/paymentViewContext";
import { ServicesAreas } from "./components/servicesAreas/servicesAreas";
import { Header } from "./components/home/header/Header";
import { UnlokedServices } from "./pages/unlokedServices/UnlokedServices";
import { Contact } from "./pages/contact/Contact";
import { Notifications } from "./pages/notifications/Notifications";
import { OtherProfile } from "./pages/otherProfile/OtherProfile";
import { UserInfoContextProvider } from "./context/userInfoContext";
import { SecurityTerms } from "./pages/securityTerms/SecurityTerms";
import { SelectAreaService } from "./pages/selectAreaService/SelectAreaService";


function App() {


  function RootNavigator() {

    const { user, setUser } = useContext(AuthenticatedUserContext);
    const [loading, setLoading] = useState(true);

    const ProtectedRoute = ({ children }) => {
      if (!user) {
        return <Navigate to="/login" />;
      }
      return children;
    };

    const router = createBrowserRouter([

      {
        path: "/",
        element: (
          <ProtectedRoute>
            <div>
              <Header />
              <Outlet />
            </div>
          </ProtectedRoute>
        ),
        children: [
          {
            path: "/",
            element: <Home />,
          },
          {
            path: "/myProfile",
            element: <Profile />,
          },
          {
            path: "/service",
            element: <InfoService />,
          },
          {
            path: "/getCoins",
            element: <GetCoins />
          },
          {
            path: "/specialties",
            element: <ServicesAreas />
          },
          {
            path: "/myservices",
            element: <UnlokedServices />
          },
          {
            path: "/contact",
            element: <Contact />
          },
          {
            path: "/notifications",
            element: <Notifications />
          },
          {
            path: "/profile",
            element: <OtherProfile />
          },
        ],
      },
      {
        path: "/login",
        element: <Login />,
      },
      {
        path: "/register",
        element: <Register />,
      },
      {
        path: "/SecurityTerms",
        element: <SecurityTerms/>
      },
      {
        path: "/selectAreaService",
        element: <SelectAreaService/>
      }
    ]);

    useEffect(() => {
      const unsubscribe = onAuthStateChanged(auth, async authenticatedUser => {
        authenticatedUser ? setUser(authenticatedUser) : setUser(null);
        setLoading(false);
      }
      );
      return () => unsubscribe();
    }, [user]);



    if (loading) {
      return (
        <div>
          <p>carregando...</p>
        </div>
      )
    }

    return (
      <RouterProvider router={router} />
    )
  }

  return (

    <AuthenticatedUserProvider>
      <ServiceViewProvider>
        <PaymentViewProvider>
          <UserInfoContextProvider>
              <GlobalStyle />
              <RootNavigator />
          </UserInfoContextProvider>
        </PaymentViewProvider>
      </ServiceViewProvider>
    </AuthenticatedUserProvider>
  );
}

export default App;
