import styled from "styled-components"

export const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    align-items: center;
    .header{
        width: 100%;
        display: flex;
        justify-content: center;
        padding: 10px;
        box-shadow: 10px 0 20px #333;

        .logo{
            display: flex;
            align-items: center;
            font-size: 18px;
            h1{
                font-weight: 900;
                color:#333;
            }
            img{
                @media screen and (max-width:508px){
                    width : 50px;
                }
                width : 50px;
                object-fit: contain;
            }
        }
    }

    .content{
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 20px;

        .boxScreen{
            box-shadow: 0px 0px 10px #aaa;
            padding: 20px;
            border-radius: 5px;
        }
        .containerText{
            display: flex;
            justify-content: center;
        }

        .containerChoose{
            display: flex;
            justify-content: center;
            padding: 20px;
            gap: 20px;
            flex-wrap: wrap;
            .btnChoose{
                cursor: pointer;
                background-color: #037ffc;
                color: #fff;
                padding: 10px;
                font-size: 20px;
            }
            .btnChoose:hover{
                border: 0;
                background-color: #3399ff;
            }
        }
    }

    .btnBack{
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        border: solid 1px #ccc;
        font-weight: bold;
        margin-bottom: 20px;
        cursor: pointer;
    }
    .containerInstructions{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        

        .msgInfo{
            text-align: center;
            font-size: 20px;
            max-width: 300px;
        }

        .btnContinue{
            cursor: pointer;
            background-color: #037ffc;
            color: #fff;
            padding: 10px;
            font-size: 20px;
            border: 0;
            font-weight: 900;
            margin-top: 20px;
        }
    }

    
`