import React, { useState } from 'react'
import { Container } from './style'
import { Link, useNavigate } from 'react-router-dom'
import { createUserWithEmailAndPassword } from 'firebase/auth'
import { auth, db } from '../../config/firebase'
import { addDoc, collection, serverTimestamp } from 'firebase/firestore'
import Logo from "../../img/logores.png"

export const Register = () => {
  const [input, setInput] = useState({
    username: "",
    email: "",
    password: "",
    repeatPassword: "",
    phoneNumber: "",
    userType: null,
    age18: false
  })

  const userRef = collection(db, "users")
  const [errRegister, setErrRegister] = useState()
  const navigate = useNavigate()

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "email") {
      const emailInLowerCase = value.toLowerCase();
      setInput(prev => ({ ...prev, [name]: emailInLowerCase }));
    } else {
      setInput(prev => ({ ...prev, [name]: value }));
    }
  };

  return (
    <Container>
      <div className="containerLogo">

        <h1>FreelaVerse</h1>
        <img src={Logo} alt="" />
      </div>{/*containerLogo */}
      <div className="form">
        <h2>Cadastre-se abaixo</h2>
        <form>
          <input
            type="text"
            placeholder='nome de usuário'
            name="username"
            onChange={handleChange}
            required // Adicione o atributo "required" aqui
          />
          <input
            type="text"
            placeholder='email'
            name='email'
            onChange={handleChange}
            required // Adicione o atributo "required" aqui
          />
          <input
            type="password"
            placeholder='senha'
            name='password'
            onChange={handleChange}
            required // Adicione o atributo "required" aqui
          />
          <input
            type="password"
            placeholder='repita a sua senha'
            name='repeatPassword'
            onChange={handleChange}
            required // Adicione o atributo "required" aqui
          />
          <input
            type='fone'
            placeholder='seu número de telefone'
            name='phoneNumber'
            onChange={handleChange}
            required // Adicione o atributo "required" aqui
          />

          <h3>Selecione o seu tipo de usuário!</h3>
          <div className="containerUserType">
            <input
              type="radio"
              id='client'
              value="client"
              name='userType'
              onChange={handleChange}
              required // Adicione o atributo "required" aqui
            />
            <label htmlFor="client">Cliente</label>

            <input
              type="radio"
              id='Professional'
              value="professional"
              name='userType'
              onChange={handleChange}
              required // Adicione o atributo "required" aqui
            />


            <label htmlFor="Professional">Profissional</label>
          </div>{/*containerUserType */}
          <div className='ageField'>
            <input
              type="checkbox"
              id="age18"
              name="age18"
              onClick={() => {
                let aux = input
                aux.age18 = !input.age18
                setInput(aux)
                console.log(input)
              }}
              required
            />
            <label htmlFor="age18">Declaro ter 18 anos ou mais</label>

          </div>{/*ageField */}
          <span className='terms'>Ao se cadastrar, você concorda com as nossas <Link to="/SecurityTerms">Políticas e termos de serviço</Link></span>
          <button
            onClick={(e) => {
              e.preventDefault()

              //Validations
              if (input.username === "")
                return setErrRegister("Campo nome de usuário obrigatório!")
              else
                if (input.email === "")
                  return setErrRegister("Campo email é obrigatório!")
                else if (input.password === "")
                  return setErrRegister("Campo senha é obrigatório!")
                else if (input.repeatPassword !== input.password)
                  return setErrRegister("Senha e confirmação de senha diferentes!")
                else if (input.phoneNumber === "")
                  return setErrRegister("Campo número de telefone é obrigatório!")
                else if (input.userType === null)
                  return setErrRegister("Seleção de tipo de usuário obrigatório!")
                else if (input.age18 !== true)
                  return setErrRegister("É necessário ter 18 anos ou mais!")
                else {
                  createUserWithEmailAndPassword(auth, input.email, input.password)
                    .then(() => {
                      console.log(input.userType)
                      if (input.userType === "professional") {
                        navigate("/specialties")
                      } else {
                        navigate("/")
                      }
                      addDoc(userRef, {
                        userName: input.username,
                        email: input.email,
                        userType: input.userType,
                        phoneNumber: input.phoneNumber,
                        coins: 0,
                        img: "https://img.quizur.com/f/img6089cd97d15d17.69644284.jpg?lastEdited=1619643845",
                        createdAt: serverTimestamp(),
                        specialties: ['suporte-tecnico']
                      })
                      console.log("SignUp Success!")
                    })
                    .catch((err) => {
                      console.log("erro:", err.message)
                      if (err.message === "Firebase: Error (auth/email-already-in-use).") {
                        setErrRegister("Email já cadastrado!")
                      } if (err.message === "Firebase: Error (auth/invalid-email).") {
                        setErrRegister("Email inválido!")
                      }
                      if (err.message === "Firebase: Password should be at least 6 characters (auth/weak-password).") {
                        setErrRegister("A senha deve conter no mínimo 6 dígitos")
                      }
                    })
                }
            }
            }
          >Cadastrar-se</button>
          <h3>{errRegister && errRegister}</h3>
        </form>
      </div>{/*form*/}





      <p>Já é cadastrado? <Link to="/Login">Fazer Login</Link></p>
    </Container>
  )
}
