import React, { useEffect, useState } from 'react'
import { Container } from './style'
import { useNavigate } from 'react-router-dom'
import { collection, doc, onSnapshot, query, updateDoc, where } from 'firebase/firestore'
import { auth, db } from '../../config/firebase'

export const ServicesAreas = () => {
  const navigate = useNavigate()

  const [services, setServices] = useState([
    {
      name: "programacao-sites",
      btnName:"Programação de Sites",
      locked: false
    },
    {
      name: "programacao-aplicativos",
      btnName:"Programação de Aplicativos",
      locked: false
    },
    {
      name: "pedreiro",
      btnName:"Serviços de Constrição",
      locked: false
    },
    {
      name: "marcenaria",
      btnName:"marcenaria",
      locked: false
    },
    {
      name: "ilustracao",
      btnName:"Ilustração",
      locked: false
    },
    {
      name: "design-grafico",
      btnName:"Design Gráfico",
      locked: false
    },
    {
      name: "redacao-conteudo",
      btnName:"Redação de Conteúdo",
      locked: false
    },
    {
      name: "marketing-digital",
      btnName:"Marketing Digital",
      locked: false
    },
    {
      name: "traducao",
      btnName:"Tradução",
      locked: false
    },
    {
      name: "consultoria",
      btnName:"Consultoria",
      locked: false
    },
    {
      name: "video-edicao",
      btnName:"Edição de Video",
      locked: false
    }
  ])

  const [userSpecialties, setUserSpecialties] = useState([])
  //UserInfo

  const [userInfo, setUserInfo] = useState()
  const colRef = collection(db, "users")
  const { currentUser } = auth;
  const findUsername = query(colRef, where("email", "==", currentUser.email))

  useEffect(() => {
    onSnapshot(findUsername, (snapshot) => {
      let users = []
      snapshot.docs.forEach((doc) => {
        users.push({ ...doc.data(), id: doc.id })
        setUserInfo(users)
      })
    })
  }, [])

  useEffect(()=>{
    if (userInfo !== undefined) {

      const updateLockedStatus = (index, newLockedValue) => {
        const updatedServices = [...services];
        updatedServices[index].locked = newLockedValue;
        setServices(updatedServices);
      };

      services.map((item, count)=>{
        userInfo[0].specialties.map((item1)=>{
          if(item.name === item1){
            updateLockedStatus(count, true)
            return
          }
          
        })
      })
    }
  },[userInfo])


  return (
    <Container>
      <div className="containerTitle">
        
      <h2>Habilite ou desabilite as suas áreas de atuação nos botões abaixo</h2>
      </div>{/*containerTtle */}

      {userInfo ?  // Verifica se userInfo está definido 
        <>

            {

              services.map((item, count) => {

                return (
                  <div className="containerAllItems" key={item.name}>
                    
                    {item.locked ?
                      <button
                        onClick={() => {
                          const updatedServices = [...services]; // Cria uma cópia do array
                          updatedServices[count].locked = !updatedServices[count].locked; // Modifica a cópia

                          setServices(updatedServices);


                          const userRef = doc(db, "users", userInfo[0].id);

                          const allSpecialties = [...userInfo[0].specialties]
                          
                          const indiceItemRemover = allSpecialties.indexOf(item.name);

                          // 2. Remova o item com base no índice
                          allSpecialties.splice(indiceItemRemover, 1);

                          let data = {
                            coins: userInfo[0].coins,
                            createdAt: userInfo[0].createdAt,
                            email: userInfo[0].email,
                            img: userInfo[0].img,
                            phoneNumber: userInfo[0].phoneNumber,
                            userName: userInfo[0].userName,
                            userType: userInfo[0].userType,
                            specialties: allSpecialties
                          };

                          updateDoc(userRef, data)

                        }}
                        className='markedBtn'
                      >{item.btnName}</button>
                      
                      :
                      <button
                      onClick={() => {
                        const userRef = doc(db, "users", userInfo[0].id);

                        let allSpecialties = [...userInfo[0].specialties]
                        allSpecialties.push(item.name)
                        let data = {
                          coins: userInfo[0].coins,
                          createdAt: userInfo[0].createdAt,
                          email: userInfo[0].email,
                          img: userInfo[0].img,
                          phoneNumber: userInfo[0].phoneNumber,
                          userName: userInfo[0].userName,
                          userType: userInfo[0].userType,
                          specialties: allSpecialties
                        };

                        updateDoc(userRef, data)
                          .then(docRef => {

                          })
                          .catch(error => {
                            console.log(error);
                          })
                        const updatedServices = [...services]; // Cria uma cópia do array
                        updatedServices[count].locked = !updatedServices[count].locked; // Modifica a cópia
                        setServices(updatedServices);
                      }}
                        className='containerSingleArea'
                      >{item.btnName}</button>
                    }

                  
                </div>/*containerAllItems*/
                )
              })

            }



          <button onClick={() => {
            navigate("/")
          }} className='btnSend'>Ir para o início</button>
        </>
        :
        <p>Carregando...</p>
      }

    </Container>
  )
}
