import { createContext, useState } from "react";

export const ServiceViewContext = createContext()

export const ServiceViewProvider = ({ children })=>{
    const[service, setService] = useState([]);
    return(
      <ServiceViewContext.Provider value={{service, setService}}>
        {children}
      </ServiceViewContext.Provider>
    )
  }