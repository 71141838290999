import styled from "styled-components"

export const Container = styled.div`
    width: 100%;
    height: 40px;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: solid 1px #ccc;
    h2{
        font-weight: 900;
    }
`