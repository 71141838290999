import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
    @import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Roboto:wght@400;500;700;900&display=swap');
    
    *{
        margin: 0;
        padding: 0;
        outline: 0;
        box-sizing: border-box;
    }

    html, body, #root{
        height: 100%;
    }

    body {
        font: 14px 'Roboto', sans-serif;
        color: #333;
        -webkit-font-smoothing: antialiased !important;
    }

    .containerView{
        height: 100%;
    }

    ul {
        list-style: none;
    }
`;